export default [
  { label: 'Agriculture & Timber', value: 'Agriculture & Timber' },
  { label: 'Chemical', value: 'Chemical' },
  { label: 'Energy & Mining', value: 'Energy & Mining' },
  { label: 'Food & Beverage', value: 'Food & Beverage' },
  { label: 'Health Care', value: 'Health Care' },
  { label: 'Hospitality', value: 'Hospitality' },
  { label: 'Information Technology (IT)', value: 'Information Technology (IT)' },
  { label: 'Manufacture', value: 'Manufacture' },
  { label: 'Music & Entertainment', value: 'Music & Entertainment' },
  { label: 'Organizations', value: 'Organizations' },
  { label: 'Real Estate', value: 'Real Estate' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Services', value: 'Services' },
  { label: 'Transportation', value: 'Transportation' },
  { label: 'Others', value: 'Others' },
]
