var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo",attrs:{"to":{ name:'dashboard' }}},[_c('b-img',{staticClass:"h-100 w-100",staticStyle:{"max-width":"160px !important","max-height":"160px !important"},attrs:{"src":require('@/assets/images/logo/TraceBit-logo.svg'),"alt":"Logo"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"7"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"5"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" Sign Up / Request Access ")]),(!_vm.submitted)?_c('div',[_c('b-card-text',[_c('span',[_vm._v(" Currently sign up process is curated by request."),_c('br'),_vm._v(" Please fill the request form below. ")])]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label-for":"register-username"}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Full Name")]),_c('small',{staticClass:"text-danger font-weight-bold"},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"Fullname","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"register-name","state":errors.length > 0 ? false:null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-email"}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Email")]),_c('small',{staticClass:"text-danger font-weight-bold"},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-phone"}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Phone")]),_c('small',{staticClass:"text-danger font-weight-bold"},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"Phone","vid":"phone","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-phone","name":"register-phone","state":errors.length > 0 ? false:null},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-address"}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Address")]),_c('validation-provider',{attrs:{"name":"Address","vid":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"register-address","name":"register-address","state":errors.length > 0 ? false:null,"trim":"","rows":"1"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-company-name"}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Company Name")]),_c('small',{staticClass:"text-danger font-weight-bold"},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"Company Name","vid":"company-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-company-name","name":"register-company-name","state":errors.length > 0 ? false:null},model:{value:(_vm.company_name),callback:function ($$v) {_vm.company_name=$$v},expression:"company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-industry-name"}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Industry Name")]),_c('small',{staticClass:"text-danger font-weight-bold"},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"Industry Name","vid":"industry-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"industry-name",staticClass:"w-100",attrs:{"id":"industry-name","reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.industryNames,"placeholder":"Choose Industry Name"},model:{value:(_vm.industry_name),callback:function ($$v) {_vm.industry_name=$$v},expression:"industry_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"privacy-agreement","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" I confirm that I have read, agree to TraceBit's "),_c('b-link',{attrs:{"to":{name:'privacy-policy'}}},[_vm._v(" Privacy policy, ")]),_c('b-link',{attrs:{"to":{name:'term-condition'}}},[_vm._v(" Terms and Conditions ")]),_vm._v(" and concerns to receive emails, general messages from TraceBit ")],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":!_vm.status || invalid || _vm.disableBtn}},[_vm._v(" Submit ")])],1)]}}],null,false,4233743203)}),_c('p',{staticClass:"mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" Log in instead")])])],1)],1):_c('div',[_c('b-card-text',[_c('span',[_vm._v(" Thank you for your request. We'll let you know when your account is ready via email/WhatsApp. ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }