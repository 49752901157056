<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        :to="{ name:'dashboard' }"
        class="brand-logo"
      >
        <b-img
          class="h-100 w-100"
          style="max-width: 160px !important; max-height: 160px !important"
          :src="require('@/assets/images/logo/TraceBit-logo.svg')"
          alt="Logo"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Sign Up / Request Access
          </b-card-title>
          <div v-if="!submitted">
            <b-card-text>
              <span>
                Currently sign up process is curated by request.<br>
                Please fill the request form below.
              </span>
            </b-card-text>

            <!-- form -->
            <validation-observer
              ref="registerForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="register"
              >
                <!-- fullname -->
                <b-form-group label-for="register-username">
                  <label for="label">Full Name</label>
                  <small class="text-danger font-weight-bold"> *</small>
                  <validation-provider
                    #default="{ errors }"
                    name="Fullname"
                    vid="name"
                    rules="required"
                  >
                    <b-form-input
                      id="register-name"
                      v-model="name"
                      name="register-name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- email -->
                <b-form-group label-for="register-email">
                  <label for="label">Email</label>
                  <small class="text-danger font-weight-bold"> *</small>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="userEmail"
                      name="register-email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- phone -->
                <b-form-group label-for="register-phone">
                  <label for="label">Phone</label>
                  <small class="text-danger font-weight-bold"> *</small>
                  <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    vid="phone"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="register-phone"
                      v-model="phone"
                      name="register-phone"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- address -->
                <b-form-group label-for="register-address">
                  <label for="label">Address</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Address"
                    vid="address"
                  >
                    <b-form-textarea
                      id="register-address"
                      v-model="address"
                      name="register-address"
                      :state="errors.length > 0 ? false:null"
                      trim
                      rows="1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- company name -->
                <b-form-group label-for="register-company-name">
                  <label for="label">Company Name</label>
                  <small class="text-danger font-weight-bold"> *</small>
                  <validation-provider
                    #default="{ errors }"
                    name="Company Name"
                    vid="company-name"
                    rules="required"
                  >
                    <b-form-input
                      id="register-company-name"
                      v-model="company_name"
                      name="register-company-name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- industry name -->
                <b-form-group label-for="register-industry-name">
                  <label for="label">Industry Name</label>
                  <small class="text-danger font-weight-bold"> *</small>
                  <validation-provider
                    #default="{ errors }"
                    name="Industry Name"
                    vid="industry-name"
                    rules="required"
                  >
                    <v-select
                      id="industry-name"
                      ref="industry-name"
                      v-model="industry_name"
                      :reduce="val => val.value"
                      class="w-100"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="industryNames"
                      placeholder="Choose Industry Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="privacy-agreement"
                    v-model="status"
                    name="checkbox-1"
                  >
                    I confirm that I have read, agree to TraceBit's
                    <b-link :to="{name:'privacy-policy'}">
                      Privacy policy,
                    </b-link>
                    <b-link :to="{name:'term-condition'}">
                      Terms and Conditions
                    </b-link>
                    and concerns to receive emails, general messages from TraceBit
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="!status || invalid || disableBtn"
                >
                  Submit
                </b-button>
              </b-form>
            </validation-observer>

            <p class="mt-2">
              <span>Already have an account?</span>
              <b-link :to="{name:'auth-login'}">
                <span>&nbsp;Log in instead</span>
              </b-link>
            </p>
          </div>
          <div v-else>
            <b-card-text>
              <span>
                Thank you for your request. We'll let you know when your account is ready via email/WhatsApp.
              </span>
            </b-card-text>
          </div>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormCheckbox, BRow, BCol, BLink, BButton, BForm, BFormTextarea, BFormGroup, BFormInput, BImg, BCardTitle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required, email, numeric } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import industryNames from '@/@fake-db/data/other/industry_name_options'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormTextarea,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      submitted: false,
      disableBtn: false,
      status: false,
      name: '',
      userEmail: '',
      phone: '',
      address: '',
      industry_name: '',
      company_name: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      industryNames,
      // validation
      required,
      email,
      numeric,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      this.disableBtn = true
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt.register({
            name: this.name,
            email: this.userEmail,
            phone: this.phone,
            address: this.address,
            company_name: this.company_name,
            industry_name: this.industry_name,
          }).then(() => {
            this.submitted = true
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error has occured',
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
            this.$refs.registerForm.setErrors(error.response.data.message)
            this.disableBtn = false
          })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
// Vue select dropdown height
#industry-name ul.vs__dropdown-menu {
  max-height: 200px;
}
@media (max-width: 420px) {
  .auth-bg {
    padding-top: 25%;
  }
  #industry-name ul.vs__dropdown-menu {
    max-height: 150px;
  }
}
</style>
